import Vuex from 'vuex'
import Vue from 'vue'
import authenticate from './modules/Authenticate'
import staticData from './modules/StaticData'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        authenticate,
        staticData
    }
});

export default store;