import {DATA_SET_SALONS, DATA_SET_SALONAREAS, DATA_SET_CATEGORIES} from '../../mutation-types';

export default {
    [DATA_SET_SALONS](state, { salons }) {
        state.salons = salons;
    },
    [DATA_SET_SALONAREAS](state, { salonAreas }) {
        state.salonAreas = salonAreas;
    },
    [DATA_SET_CATEGORIES](state, { categories }) {
        state.categories = categories;
    },
};
