import {DATA_SET_SALONS, DATA_SET_SALONAREAS} from '../../mutation-types';
import axios from 'axios';
import config from '../../../configs/ApplicationConfig';

const rejectSalons = [13,16,24,29,41,59,71,73,75,76,78,110,111];

export async function setSalons({ commit }) {
    let salons = [];
    let response = await axios.get(config.elearningEndpoint + '/api/v1/salons');
    if (response.data.meta.success) {
        // Reject report SALONTEST id 24
        salons = response.data.data.filter( salon => !rejectSalons.includes(salon.id) && salon.id > 0);
    }

    commit(DATA_SET_SALONS, { salons });
}

export async function setSalonAreas({ commit }) {
    let salonAreas = [];
    let response = await axios.get(config.elearningEndpoint + '/api/v1/salon-areas');
    if (response.data.meta.success) {
        salonAreas = response.data.data;
    }

    commit(DATA_SET_SALONAREAS, { salonAreas });
}