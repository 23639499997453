import Vue from 'vue'
import Router from 'vue-router'
import App from '../App';
const CustomerExperienceAdmin = () => import('../pages/CustomerExperienceAdmin')
const Dashboard = () => import('../pages/Dashboard/Dashboard')
const TestCompleteReport = () => import('../pages/Report/TestCompleteReport')

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            component: App,
            children: [
                {
                    path: '',
                    component: CustomerExperienceAdmin,
                    children: [
                        {
                            path: '',
                            name: 'Dashboard',
                            component: Dashboard,
                        },
                        {
                            path: 'dashboard',
                            name: 'Dashboard',
                            component: Dashboard,
                        },
                        {
                            path: 'bao-cao-ti-le-quet',
                            name: 'Báo cáo tỉ lệ quét bill trên app trải nghiệm khách hàng',
                            component: TestCompleteReport
                        },
                    ]
                }
            ]
        }
    ],
    mode: 'history',
    linkActiveClass: "active", // active class for non-exact links.
    linkExactActiveClass: "exact-active" // active class for *exact* links.
})